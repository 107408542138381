<template>
  <div id="ContasReceberModal" >
    <v-card class="container pa-0" >
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
                    class="justify-space-between py-1 px-4">
        <div >
          <!--Cabeçalho com busca para a ação novo -->
          <div v-if="dados_editar.acao == 'NOVO'">
            <v-row>
              <v-col class="title-page" cols="5">Novo Documento</v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="proposta_incluir"
                  filled
                  dense
                  outlined
                  background-color="#FFF"
                  placeholder="Nº Proposta"
                  label="Nº Proposta"
                  maxlength="100"
                >
                </v-text-field>
              </v-col>
              <v-col> 
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  outlined
                  tabindex="-1"
                  @click="buscaProposta(proposta_incluir)"
                >
                  <v-icon class="false-icon ml-3 mr-2" color="#FFF">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <span class="title-page-menor ml-2 mr-2"> - </span><span class="title-page-menor">{{dados_editar.empreendcobr_descricao}} ( {{dados_editar.empreendcobr_parcela_inicial}} / {{dados_editar.empreendcobr_parcela_final}} )</span>
            <span
              v-if="dados_editar.empreendcobr_situacao == 'Pago'"
              class="status_ativo ml-3"
              >{{ dados_editar.empreendcobr_situacao }}
            </span>
            <span
              v-if="dados_editar.empreendcobr_situacao == 'Aberto'"
              class="status_pendente ml-3"
              >{{ dados_editar.empreendcobr_situacao }}
            </span>
            <span
              v-if="dados_editar.empreendcobr_situacao == 'Rescindido'"
              class="status_cancelado ml-3"
              >{{ dados_editar.empreendcobr_situacao }}
            </span>
            <span
              v-if="dados_editar.empreendcobr_situacao == 'Inativo'"
              class="status_cancelado ml-3"
              >{{ dados_editar.empreendcobr_situacao }}
            </span>
          </div>
          <!--Cabeçalho padrão para ações de pagar e de Edição -->
          <div v-if="dados_editar.acao != 'NOVO'">
            <span class="title-page">Documento nº {{dados_editar.cod_empreendcobranca}} </span>
            <span
              class="title-page-menor">
              - {{dados_editar.empreendcobr_descricao}} ({{dados_editar.empreendcobr_parcela_inicial}}/{{dados_editar.empreendcobr_parcela_final}})
            </span>
            <span
              v-if="dados_editar.empreendcobr_situacao == 'Pago'"
              class="status_ativo ml-3"
              >{{ dados_editar.empreendcobr_situacao }}
            </span>
            <span
              v-if="dados_editar.empreendcobr_situacao == 'Aberto'"
              class="status_pendente ml-3"
              >{{ dados_editar.empreendcobr_situacao }}
            </span>
            <span
              v-if="dados_editar.empreendcobr_situacao == 'Rescindido'"
              class="status_cancelado ml-3"
              >{{ dados_editar.empreendcobr_situacao }}
            </span>
            <span
              v-if="dados_editar.empreendcobr_situacao == 'Inativo'"
              class="status_cancelado ml-3"
              >{{ dados_editar.empreendcobr_situacao }}
            </span>
          </div>
        </div>

          <v-btn
          @click="fechar()"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
       </v-card-title>
       
      <v-card-text class="wrapper pa-0">
        <v-divider dark></v-divider>

        <div v-bind:style="{ 'background-color':COR_PRINCIPAL }"
             v-if="dados_editar.acao != 'NOVO'">

          <!-- Cabecalho -------------------- -->
          <v-row class="ml-1 mt-0">
            <v-col>
              <label style="color:white;font-size:15px;" class="mr-4">{{dados_editar.empreend_venda.pessoa_venda.pessoa_nome}}</label>
              <label class="" style="color:silver;font-size:14px;">CPF / CNPJ: </label> <label style="color:white;font-size:15px;" class="mr-4">{{maskCpfCnpj(dados_editar.empreend_venda.pessoa_venda.pessoa_cpf)}}{{maskCpfCnpj(dados_editar.empreend_venda.pessoa_venda.pessoa_cnpj)}}</label>
              <label style="color:silver;font-size:14px;">Telefone: </label> <label style="color:white;font-size:15px;">{{dados_editar.empreend_venda.pessoa_venda.fone_celular1}} {{dados_editar.empreend_venda.pessoa_venda.fone_celular2}}</label>
            </v-col>
          </v-row>
          <v-row class="ml-1 mt-n5">
            <v-col>
              <label style="color:silver;font-size:14px;font-style: bold;">Empreendimento: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_editar.empreend_venda.vendas.empreend_nome}}</label>
              <label style="color:silver;font-size:14px;">Quadra: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_editar.empreend_venda.empreendvenda_imovel.quadra}}</label>
              <label style="color:silver;font-size:14px;">Lote: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_editar.empreend_venda.empreendvenda_imovel.lote}}</label>
            </v-col>
          </v-row>
          <v-row class="ml-1 mt-n5">
            <v-col>
              <label style="color:silver;font-size:14px;font-style: bold;">Nº Proposta: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_editar.cod_empreendvenda}}</label>
              <label style="color:silver;font-size:14px;">  Contrato: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_editar.empreend_venda.empreendvenda_contrato}}</label>
              <label style="color:silver;font-size:14px;">  Data Contrato: </label> <label style="color:white;font-size:15px;">{{formatDate(dados_editar.empreend_venda.empreendvenda_contr_data)}}</label>
            </v-col>
          </v-row>   
        </div>

        <!-- Dados ---------------------- -->
        <div>
          <v-row class="mt-6 px-5">
            <v-col>
              <v-form
                ref="form"
                v-model="valid">
                <fieldset class="rounded" style="border: none">
                  <v-row class="mt-n5">
                    <!-- DATA VENCIMENTO -->
                    <v-col col="12" class="px-2">

                      <DatePicker
                        v-model="dados_editar.empreendcobr_parc_dt"
                        label="Data Vencimento"
                        class="mt-1"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense
                        placeholder="dia/mês/ano"
                        :rules="datavenctoRules"
                        :readonly="dados_editar.acao2 == 'VISUALIZAR' || dados_editar.acao == 'PAGAR'"
                        >
                      </DatePicker>

                    </v-col>

                    <!-- DATA PAGAMENTO -->
                    <v-col col="6" class="px-2">
                      <DatePicker
                        v-model="dados_editar.empreendcobr_pagto_dt"
                        label="Data Pagamento"
                        class="mt-1"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense
                        placeholder="dia/mês/ano"
                        :rules="datavenctoRules"
                        :readonly="dados_editar.acao != 'PAGAR'|| dados_editar.acao2 == 'VISUALIZAR'"
                        :disabled="dados_editar.acao == 'EDITAR'"
                        >
                      </DatePicker>
                      <!-- <DatePicker
                        v-model="dados_editar.empreendcobr_pagto_dt"
                        label="Data Pagamento"
                        class="mt-1"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense
                        placeholder="dia/mês/ano"
                        :rules="dataPagtoRules"
                        :disabled="dados_editar.acao == 'EDITAR'"
                        :readonly="dados_editar.acao == 'VISUALIZAR'"
                        >
                       </DatePicker> -->
                    </v-col>
                    
                    <!-- VALOR PARCELA -->
                    <v-col col="6" class="px-2">
                      <VuetifyMoney
                        v-model="dados_editar.empreendcobr_parc_valor"
                        class="mt-1"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Parcela"
                        label="Valor Parcela"
                        :readonly="dados_editar.acao != 'NOVO'"
                        reverse
                        :clearable="false"
                      >
                      </VuetifyMoney>
                    </v-col>
                  </v-row>  
                    
                  <v-row class="mt-n5">  
                    <!-- OBS ACRÉSCIMO -->
                    <v-col cols="8" class="pt-0 px-2">
                      <v-text-field
                        v-model="dados_editar.empreendcobr_parc_acres_obs"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Descrição Acréscimo"
                        label="Descrição Acréscimo"
                        maxlength="100"
                        :clearable="false"
                        :readonly="dados_editar.acao2 == 'VISUALIZAR'"
                      >
                      </v-text-field>
                    </v-col>

                    <!-- VALOR ACRÉSCIMO -->
                    <v-col cols="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados_editar.empreendcobr_parc_acres_valor"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Acréscimo"
                        label="Valor Acréscimo"  
                        reverse
                        :clearable="false"
                        :readonly="dados_editar.acao2 == 'VISUALIZAR'"
                      >

                      </VuetifyMoney>
                    </v-col>
                  </v-row>  
                    
                  <v-row class="mt-n5">
                    <!-- OBS DESCONTO -->
                    <v-col cols="8" class="pt-0 px-2">
                      <v-text-field
                        v-model="dados_editar.empreendcobr_parc_desc_obs"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Descrição Desconto"
                        :clearable="false"
                        label="Descrição Desconto"
                        maxlength="100"
                        :readonly="dados_editar.acao2 == 'VISUALIZAR'">
                      </v-text-field>
                    </v-col>

                    <!-- VALOR DESCONTO -->
                    <v-col cols="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados_editar.empreendcobr_parc_desc_valor"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Desconto"
                        label="Valor Desconto"
                        :clearable="false"
                        reverse
                        :readonly="dados_editar.acao2 == 'VISUALIZAR'"
                        >
                      </VuetifyMoney>
                    </v-col>
                  </v-row>  
                    
                  <v-row class="mt-n5">                    <!-- SOFRE AMORTIZAÇÃO -->
                    <v-col col="6" class="pt-0 px-2">
                     <v-select
                        v-model="dados_editar.empreendcobr_aplica_amortizacao"
                        label="Sofre Amortização"
                        class="w-100 mb-n3"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        :items="opcoes_amortizacao"
                        :readonly="dados_editar.acao != 'EDITAR' || dados_editar.acao != 'NOVO' || dados_editar.acao == 'VISUALIZAR'"
                        item-text="value"
                        item-key="id"
                        
                      ></v-select>
                    </v-col>

                    <!-- SOFRE REAJUSTE -->
                    <v-col col="6" class="pt-0 px-2">
                       <v-select
                        v-model="dados_editar.empreendcobr_aplica_reajuste"
                        label="Sofre Reajuste"
                        class="w-100 mb-n3"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        :items="opcoes_reajuste"
                        :readonly="dados_editar.acao != 'EDITAR' || dados_editar.acao != 'NOVO' || dados_editar.acao == 'VISUALIZAR'"
                        item-text="value"
                        item-key="id"
                      ></v-select>
                    </v-col>

                    <!-- DESCONTO ANTECIPAÇÃO -->
                    <v-col col="6" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados_editar.empreendcobr_parc_ant_desc"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Desconto Antecipação"
                        label="Desconto Antecipação"
                        reverse
                        :readonly="dados_editar.acao != 'NOVO'"
                        
                      >
                      </VuetifyMoney>
                    </v-col>
                  </v-row>  

                  <v-row class="mt-n5">
                    <!-- OBSERVAÇÃO -->
                    <v-col cols="8" class=" pt-0 px-2">
                      <v-textarea
                      v-model="dados_editar.empreendcobr_obs"
                      class="mt-1"
                      rows="6"
                      background-color="#FFF"
                      label="Observação"
                      placeholder="Observação"
                      filled
                      :clearable="false"
                      dense
                      outlined
                      maxlength="600"
                      counter="600"
                      :readonly="dados_editar.acao2 == 'VISUALIZAR'"
                      ></v-textarea>
                    </v-col>

                    <!-- VALOR JUROS -->
                    <v-col cols="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados_editar.empreendcobr_pagto_valor_juros"
                        class="mt-1"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Juros"
                        label="Valor Juros"
                        reverse
                        :clearable="false"
                        :readonly="dados_editar.acao == 'EDITAR' || dados_editar.acao2 == 'VISUALIZAR'"
                      >
                      {{dados_editar.empreendcobr_pagto_valor_juros}}
                      </VuetifyMoney>
                    </v-col>
                  </v-row>

                  <v-row class="row-value">
                    <v-col cols="8" class="pt-n1 px-2"></v-col>

                    <!-- VALOR MULTA -->
                    <v-col cols="4" class="px-2">
                      <VuetifyMoney
                        v-model="dados_editar.empreendcobr_pagto_valor_multa"
                        class="mt-n4"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Multa"
                        label="Valor Multa"
                        reverse
                        :clearable="false"
                        :readonly="dados_editar.acao == 'EDITAR' || dados_editar.acao2 == 'VISUALIZAR'"                    
                      >
                      {{dados_editar.empreendcobr_pagto_valor_multa}}
                      </VuetifyMoney>
                    </v-col>
                  </v-row>  

                  <v-row class="mt-n3">
                    <v-col cols="8" class="pt-0 px-2"></v-col>

                    <!-- VALOR FINAL -->
                    <v-col cols="4" class="pt-0 px-2">
                      <VuetifyMoney
                        v-model="dados_editar.empreendcobr_parc_valor_final"
                        class="mt-n1"
                        filled
                        dense
                        outlined
                        placeholder="Valor Final"
                        label="Valor Final"
                        reverse
                        :clearable="false"
                        :readonly="dados_editar.acao2 == 'VISUALIZAR'"
                      >
                      {{dados_editar.empreendcobr_parc_valor_final}}
                      </VuetifyMoney>
                    </v-col>
                  </v-row> 
                 
                  <v-row class="mt-n3" v-if="dados_editar.acao == 'PAGAR'">
                    <v-col cols="12" class="pt-0 px-2">
                      <v-data-table
                        :key="key_grade_formapagamento"
                        class="rounded-lg tabela"
                        flat
                        :items="dados_editar.paymentTableData"
                        :headers="paymentTableHeaders"
                        :items-per-page="10"
                        hide-default-header
                        hide-default-footer
                        loading-text="Carregando...  aguarde..."
                        no-data-text="Nenhum Registro Encontrado"
                        no-results-text="Nenhum Registro Encontrado">
                        <template v-slot:top>
                          <v-row class="d-flex align-center justify-between">
                            <v-col col="12">
                              <v-toolbar flat class="rounded-lg">
                                <p
                                  class="font-primary font-weight-bold body-1 text-center mt-1"
                                  :style="{ 'color':COR_SUBTITULO }">
                                  Formas de Pagamento Escolhidas *
                                </p>  

                                <v-spacer></v-spacer>

                                <v-btn
                                  v-if="dados_editar.empreendcobr_situacao !== 'Pago' "
                                  @click="toggleDialogFormasPagamento"
                                  class="btn pr-6"
                                  elevation="0">
                                  <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
                                  <span class="font-weight-medium text-none" :style="{ 'color':COR_SUBTITULO }">Novo</span>
                                </v-btn>
                              </v-toolbar>
                            </v-col>
                          </v-row>
                        </template>

                        <!-- Titulos da Grade Formas de pagamento -->
                        <template v-slot:header v-if="!isMobile">
                            <thead>
                              <tr>
                                <th v-for="h in paymentTableHeaders" :key="h.value" :style="h.style_valor" :class="h.class" >
                                  <span >{{h.text}}</span>
                                </th>
                              </tr>
                            </thead>
                        </template>

                        <template v-slot:item="{item}">
                          <tr style="padding-top: 10px" v-if="!isMobile">
                            <td
                              v-if="dados_editar.empreendcobr_situacao == 'Aberto'"
                              style="max-width: 10px; cursor: pointer; padding-right: 0">
                              <v-icon
                                size="20px"
                                color="gray"
                                class="icon-menu cursor-pointer"
                                @click="openModalExcluirFormaDePagamento(item)">mdi-delete</v-icon>
                            </td>
                            <!-- <td><pre>{{ item }}</pre></td> -->
                            <td>{{ getDescricaoPorCodigo(item.cod_fin_tipo_pagamento) }} ({{ item.empreendcobrpagto_descr }})</td>
                            <td align="right">{{ formatPrice(item.empreendcobrpagto_valor) }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row> 

                  <v-row class=mb-4>
                    <v-col cols="8" class="pt-0 px-2"></v-col>
                    
                    <!-- VALOR PAGO -->
                    <v-col cols="4" class="pt-0 px-2 "  v-if="dados_editar.acao == 'PAGAR'">
                      <VuetifyMoney
                        v-model="dados_editar.empreendcobr_pagto_valor"
                        filled
                        dense
                        outlined
                        :background-color="dados_editar.empreendcobr_situacao == 'Pago' ? '#aef4cc' : '#FFFFFF'"
                        placeholder="Valor Pago"
                        label="Valor Pago"
                        reverse
                        :readonly="dados_editar.acao != 'NOVO'"
                        :clearable="false" 
                      />
                    </v-col>
                  </v-row>  
                </fieldset>  
              </v-form>
            </v-col>
          </v-row>
           <!-- FORMAS DE PAGAMENTO -->
          <v-container v-if="dialogFormasPagamento">
            <v-dialog
              persistent
              v-model="dialogFormasPagamento"
              transition="dialog-bottom-transition"
              max-width="600"
              min-width="520">
              <FormasDePagamentoModal
                class="mt-n2"
                :editar_="true"
                :dados_cobranca="dados_editar"
                :dialogFormasPagamento.sync="dialogFormasPagamento"
                :valorTroco="valorTroco"
                :cheques_escolhidos="cheques_escolhidos"
                @chequeEditado="atualizaArrayPagador"
                @criaFormaDePagamento="saveFormaDePagamento" />
            </v-dialog>
          </v-container>

        <!-- Dialog Excluir Forma de pagamento ----------------------- -->
          <v-container v-if="modalExcluirFormaDePagamento">
            <v-dialog
              v-model="modalExcluirFormaDePagamento"
              :value="modalExcluirFormaDePagamento"
              persistent
              max-width="500"
              class="d-flex justify-center">
              <v-divider></v-divider>
  
              <v-card elevation="0" class="mt-3 mb-3">
                <v-card-title class="text-h6">
                  Tem certeza que deseja EXCLUIR o pagamento?
                </v-card-title>
                <v-card-actions class="d-flex justify-center">
                  <v-spacer></v-spacer>
                  <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="modalExcluirFormaDePagamento = false">
                    Cancelar
                  </v-btn>
  
                  <v-btn class="btn white--text" color="primary accent-4" :loading="loading_excluir_forma_pagto" @click="excluirFormaDePagamento">
                    Excluir
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </div>  

      </v-card-text>
     
    
      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3 mt-8">
        <!-- 1 :  {{ dados_editar.empreendcobr_parc_valor_final }}<br>
      2 :  {{ dados_editar.empreendcobr_pagto_valor }} -->
        <v-btn
          v-if="dados_editar.acao2 != 'VISUALIZAR'"
          @click="fechar()"
          class="mr-4 btn caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="dados_editar.acao2 == 'VISUALIZAR'"
          @click="fechar()"
          class="mr-4 btn caption font-weight-medium"
          color="primary"
          text
        >
          FECHAR
        </v-btn>
        
        <v-btn
          v-show="dados_editar.acao2 != 'VISUALIZAR'"
          :disabled="(dados_editar.acao != 'EDITAR') && valorTroco < 0"
          @click="validate()"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4">
          Salvar
        </v-btn>
     
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import store_ContasReceber from "../ContasReceber/store_ContasReceber"
import { ContasReceberGet } from "../../../services/erp"
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import store_Financeiro from "../store_Financeiro";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO, MASK_CPFCNPJ, MASK_TELEFONE, } from "../../../services/constantes";
import { formatPrice, formatDate, maskCpfCnpj, validateDate } from "../../../services/funcoes";
import moment, { now } from 'moment';
import store_site from "../../../../src/store/store_site"
import ContasReceber from "./ContasReceber.vue";
import FormasDePagamentoModal from '../../Financeiro/ContasReceber/FormasDePagamentoModal.vue'
import { nextTick } from 'vue';


export default {
  name: "ContasReceberModal",

  props: ['dialog_novo_editar', 'dados_editar'],

  components: {
    VuetifyMoney,
    DatePicker,
    FormasDePagamentoModal,
  },

  data() {
    return {
      store_Financeiro    : store_Financeiro,
      store_site          : store_site,
      store_ContasReceber : store_ContasReceber,
      ContasReceber       : ContasReceber,
      formatPrice         : formatPrice,
 
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SUBTITULO     : COR_SUBTITULO,  
      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      formatDate        : formatDate,
      maskCpfCnpj       : maskCpfCnpj,

      checkbox1: true,
      checkbox2: false,
      data_atual: '15/11/2021',
      dialogFormasPagamento : false,
      cheques_escolhidos      : [],
      loading_excluir_forma_pagto : false,
      modalExcluirFormaDePagamento : false,

      key_grade_formapagamento     : 0,

      //dadosEditar       : this.dados_editar,

      proposta_incluir  : null,
      dados_proposta    : [],

      array_formas_pagamento : [
        { cod_fin_tipo_pagamento : 1, tipopagamento_descricao: 'DINHEIRO' },
        { cod_fin_tipo_pagamento : 3, tipopagamento_descricao: 'Cheque de Terceiros' },
        { cod_fin_tipo_pagamento : 6, tipopagamento_descricao: 'Depósito' },
        { cod_fin_tipo_pagamento : 5, tipopagamento_descricao: 'PIX' },
        { cod_fin_tipo_pagamento : 4, tipopagamento_descricao: 'Transferencia (DOC, TED, Internet, etc)' },
      ],
     
      /* SELECT AMORTIZAÇÃO */
      opcoes_amortizacao: [
                            { "id": 0, text: "SIM", value: "SIM" },
                            { "id": 1, text: "NÃO", value: "NÃO" },
                          ],

      /* SELECT AJUSTE */
      opcoes_reajuste: [
                          { "id": 0, text: "SIM", value: "SIM" },
                          { "id": 1, text: "NÃO", value: "NÃO" },
                       ],

      valid: true,
      form: false,

      /* TEXTOS */
      obsAcrescimoRules: [
        // (value) =>
          // (!!value && value.length < 100) ||
          // "Obs. Acréscimo deve ter menos do que 100 caracteres" + value + '  -- ' + value?.length,
        // (value) =>
        //   (!value && this.dados_editar.empreendcobr_parc_acres_obs) ||
        //   "asdfadfads" + value + '  -- ' +this.dados_editar.empreendcobr_parc_acres_obs,
      ],


      obsDescontoRules: [
        (value) =>
          (!!value && value.length < 100 ) ||
          "Obs. Desconto deve ter menos do que 100 caracteres" + (!!value && value.length < 100 ),
      ],

      datavenctoRules: [
        (value) =>
          (value && value.length >= 10) ||
          "Obs. Data de Vencimento deve ter no mínimo 8 caracteres",
        (value) =>
          (value && value.length <= 10) ||
          "Obs. Data de Vencimento deve ter menos do que 8 caracteres",
      ],

      dataPagtoRules: [
        (value) =>
          (value && value.length >= 10) ||
          (this.dados_editar.acao == "PAGAR" ? "Data deve ter ao mínimo 8 caracteres" : "")
        
      ],

      observacaoRules: [
        (value) =>
          (value && value.length < 600) ||
          "Observação deve ter menos do que 600 caracteres",
      ],

      /* NÚMEROS */
      valorRules: [
        (value) => !!value || "O Valor é obrigatório",
        (value) => /^\d+$/.test(value) || 'O Valor não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O Valor deve ter menos do que 10 caracteres",
      ],
      data_picker : {
        'min-width':'150px !important',
        //'max-width':'180px !important',
        //'margin-right':'8px !important',
        //'margin-top':'-15px !important',
        //'color':'red'
      }

    }
  },

  watch: {
    "dados_editar.empreendcobr_pagto_dt"() {
      // Recalcular multa, juros e valor final sempre que o campo data de pagamento for alterado
      if (validateDate(this.dados_editar.empreendcobr_pagto_dt)) {
        const ls_DtPagto = moment(this.dados_editar.empreendcobr_pagto_dt).format('DD/MM/YYYY')
        if(this.dados_editar.acao == 'PAGAR') {
          this.recalculaMultaJuros( this.dados_editar.empreendcobr_parc_valor, 
                                    this.dados_editar.empreendcobr_parc_dt,
                                    ls_DtPagto,
                                    this.dados_editar.empreend_venda.vendas.empreend_juros,
                                    this.dados_editar.empreend_venda.vendas.empreend_multa);
          this.recalculaValorFinal( this.dados_editar.empreendcobr_parc_valor, 
                                    this.dados_editar.empreendcobr_pagto_valor_juros,
                                    this.dados_editar.empreendcobr_pagto_valor_multa,
                                    this.dados_editar.empreendcobr_parc_acres_valor,
                                    this.dados_editar.empreendcobr_parc_desc_valor);
        }
        else if (this.dados_editar.acao == 'NOVO' && this.dados_editar.empreendcobr_parc_valor.length > 0) {
          this.recalculaMultaJuros( this.dados_editar.empreendcobr_parc_valor, 
                                    this.dados_editar.empreendcobr_parc_dt,
                                    ls_DtPagto,
                                    this.dados_editar.empreend_venda.vendas.empreend_juros,
                                    this.dados_editar.empreend_venda.vendas.empreend_multa);
          this.recalculaValorFinal( this.dados_editar.empreendcobr_parc_valor, 
                                    this.dados_editar.empreendcobr_pagto_valor_juros,
                                    this.dados_editar.empreendcobr_pagto_valor_multa,
                                    this.dados_editar.empreendcobr_parc_acres_valor,
                                    this.dados_editar.empreendcobr_parc_desc_valor);
        }
      }
      else {
        // Caso não haja data de pagamento declarada, não haverá valores a ser exibidos
        this.dados_editar.empreendcobr_pagto_valor_juros = null;
        this.dados_editar.empreendcobr_pagto_valor_multa = null;
        this.dados_editar.empreendcobr_parc_valor_final  = null;
        this.dados_editar.empreendcobr_pagto_valor       = null;
      }  
    },

    "dados_editar.empreendcobr_parc_valor"(){
      if (validateDate(this.dados_editar.empreendcobr_pagto_dt)) {
        const ls_DtPagto = moment(this.dados_editar.empreendcobr_pagto_dt).format('DD/MM/YYYY')
        if (this.dados_editar.acao == 'NOVO' && this.dados_editar.empreendcobr_parc_valor.length > 0) {
            this.recalculaMultaJuros( this.dados_editar.empreendcobr_parc_valor, 
                                      this.dados_editar.empreendcobr_parc_dt,
                                      ls_DtPagto,
                                      this.dados_editar.empreend_venda.vendas.empreend_juros,
                                      this.dados_editar.empreend_venda.vendas.empreend_multa);
            this.recalculaValorFinal( this.dados_editar.empreendcobr_parc_valor, 
                                      this.dados_editar.empreendcobr_pagto_valor_juros,
                                      this.dados_editar.empreendcobr_pagto_valor_multa,
                                      this.dados_editar.empreendcobr_parc_acres_valor,
                                      this.dados_editar.empreendcobr_parc_desc_valor);
        }
      }
    },

    "dados_editar.empreendcobr_pagto_valor_juros"(){
      // Recalcular valor final sempre que o campo de juros for alterado
        if(this.dados_editar.acao == 'PAGAR')
          this.recalculaValorFinal( this.dados_editar.empreendcobr_parc_valor, 
                                    this.dados_editar.empreendcobr_pagto_valor_juros,
                                    this.dados_editar.empreendcobr_pagto_valor_multa,
                                    this.dados_editar.empreendcobr_parc_acres_valor,
                                    this.dados_editar.empreendcobr_parc_desc_valor);
    },
    "dados_editar.empreendcobr_pagto_valor_multa"(){
      // Recalcular valor final sempre que o campo de multa for alterado
        if(this.dados_editar.acao == 'PAGAR')
          this.recalculaValorFinal( this.dados_editar.empreendcobr_parc_valor, 
                                    this.dados_editar.empreendcobr_pagto_valor_juros,
                                    this.dados_editar.empreendcobr_pagto_valor_multa,
                                    this.dados_editar.empreendcobr_parc_acres_valor,
                                    this.dados_editar.empreendcobr_parc_desc_valor);
    },
    "dados_editar.empreendcobr_parc_acres_valor"(){
      // Recalcular valor final sempre que o campo de acréscimo for alterado
        if(this.dados_editar.acao == 'PAGAR')
          this.recalculaValorFinal( this.dados_editar.empreendcobr_parc_valor, 
                                    this.dados_editar.empreendcobr_pagto_valor_juros,
                                    this.dados_editar.empreendcobr_pagto_valor_multa,
                                    this.dados_editar.empreendcobr_parc_acres_valor,
                                    this.dados_editar.empreendcobr_parc_desc_valor);
    },
    "dados_editar.empreendcobr_parc_desc_valor"(){
      // Recalcular valor final sempre que o campo de desconto for alterado
        if(this.dados_editar.acao == 'PAGAR')
          this.recalculaValorFinal( this.dados_editar.empreendcobr_parc_valor, 
                                    this.dados_editar.empreendcobr_pagto_valor_juros,
                                    this.dados_editar.empreendcobr_pagto_valor_multa,
                                    this.dados_editar.empreendcobr_parc_acres_valor,
                                    this.dados_editar.empreendcobr_parc_desc_valor);
    },
  },
  create(){
    if( this.dados_editar.acao == 'NOVO'){
      // console.log('Montou novo')
      this.dados_editar.empreend_venda.pessoa_venda          = null;
      this.dados_editar.empreendcobr_parc_valor              = null; 
      this.dados_editar.empreendcobr_parc_dt                 = null;
      this.dados_editar.empreendcobr_pagto_dt                = null;
      this.dados_editar.empreend_venda.vendas.empreend_juros = null;
      this.dados_editar.empreend_venda.vendas.empreend_multa = null;
      this.dados_editar.empreendcobr_parc_valor              = null; 
      this.dados_editar.empreendcobr_pagto_valor_juros       = null;
      this.dados_editar.empreendcobr_pagto_valor_multa       = null;
      this.dados_editar.empreendcobr_parc_acres_valor        = null;
      this.dados_editar.empreendcobr_parc_desc_valor         = null;
    }
  },

  mounted(){
    this.dados_editar.empreendcobr_parc_ant_desc = null
    // console.log("🚀 ~ file: ContasReceberModal.vue:842 ~ mounted ~ this.dados_editar:", this.dados_editar)
    this.dados_editar.empreendcobr_parc_ant_valor = null
    this.dados_editar.empreendcobr_parc_ant_dt = null
    this.dados_editar.empreendcobr_parc_ant_dt_vcto = null
    this.dados_editar.empreendcobr_parc_ant_boleto = null

    if(this.dados_editar.acao == 'EDITAR'){
      // Exibir o valor sempre que abrir no modo editar
      this.dados_editar.empreendcobr_parc_valor_final = this.dados_editar.empreendcobr_parc_valor
    }
    if(this.dados_editar.acao == 'PAGAR'){
      this.dados_editar.empreendcobr_pagto_valor = 0
      // this.dados_editar.empreendcobr_parc_acres_obs = null
      // this.dados_editar.empreendcobr_parc_desc_obs = null

      // TODOS OS CAMPOS DE DESCONTO DE ANTECIPACAO SERÃO COLOCADOS COMO NULL PARA O PAGAMENTO MANUAL
    }
    if(this.dados_editar.acao == 'PAGAR' && this.dados_editar.acao2 != 'VISUALIZAR'){
      this.dados_editar.paymentTableData = []
      // Calcular multa, juros e valor final com base na data atual ao abrir
      this.recalculaMultaJuros( this.dados_editar.empreendcobr_parc_valor, 
                                this.dados_editar.empreendcobr_parc_dt,
                                this.dados_editar.empreendcobr_pagto_dt,
                                this.dados_editar.empreend_venda.vendas.empreend_juros,
                                this.dados_editar.empreend_venda.vendas.empreend_multa);
      this.recalculaValorFinal( this.dados_editar.empreendcobr_parc_valor, 
                                this.dados_editar.empreendcobr_pagto_valor_juros,
                                this.dados_editar.empreendcobr_pagto_valor_multa,
                                this.dados_editar.empreendcobr_parc_acres_valor,
                                this.dados_editar.empreendcobr_parc_desc_valor);
    }

    if(this.dados_editar.acao2 == 'VISUALIZAR'){
      this.dados_editar.paymentTableData = this.dados_editar.cobr_pagto
      this.dados_editar.empreendcobr_pagto_valor = this.dados_editar.paymentTableData.reduce((total, item) => total + parseFloat(item.empreendcobrpagto_valor), 0)
      // this.dados_editar.empreendcobrpagto_valor = this.dados_editar?.paymentTableData.reduce((total, item) => total + parseFloat(item.empreendcobrpagto_valor), 0);
      console.log("🚀 ~ file: ContasReceberModal.vue:878 ~ mounted ~ this.dados_editar:", this.dados_editar)
    }
  },

  computed: {
    pagamentoValorFinal () {
      // console.log('pagamentoValorFinal', this.dados_editar)
      return this.dados_editar.empreendcobr_parc_valor           
             + +this.dados_editar.empreendcobr_parc_acres_valor   
             - +this.dados_editar.empreendcobr_parc_desc_valor   
             + +this.dados_editar.empreendcobr_pagto_valor_multa 
             + +this.dados_editar.empreendcobr_pagto_valor_juros 
    },

    isSaveButtonDisabled () {
      return !this.valid || !this.dados_editar.paymentTableData.length || this.valorPago.toFixed(2) < this.pagamentoValorFinal.toFixed(2)
    },

    valorPago () {
      // this.dados_editar.empreendcobrpagto_valor = dados_editar?.paymentTableData.reduce((total, item) => total + parseFloat(item.empreendcobrpagto_valor), 0);

      // console.log('valorpago', this.dados_editar?.paymentTableData?.reduce((curr, prev) => {
      //   return curr += +prev.empreendcobrpagto_valor
      // }, 0));
      console.log('11',this.dados_editar.paymentTableData);
      console.log('valorPago',this.dados_editar?.paymentTableData?.reduce((curr, prev) => curr + prev.empreendcobrpagto_valor, 0));
      return this.dados_editar?.paymentTableData?.reduce((curr, prev) => curr + prev.empreendcobrpagto_valor, 0)
    },

    valorTroco () {
      console.log('111', this.dados_editar.empreendcobr_pagto_valor);
      console.log('222', this.dados_editar.empreendcobr_parc_valor_final);
      // return

      const troco = this.dados_editar.empreendcobr_pagto_valor?.toFixed(2) - this.dados_editar.empreendcobr_parc_valor_final?.toFixed(2)
      // console.log('valor pago', this.dados_editar.empreendcobr_pagto_valor);
      // console.log('valor final', this.dados_editar.empreendcobr_parc_valor_final);
      return troco.toFixed(2) 
    },

    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    paymentTableHeaders () {
      const headers = [
        { text: 'Descrição', value: 'descricao' }, 
        { text: 'Valor', value: 'valor', style_valor: 'text-align: right' }
      ]

      if (this.dados_editar.empreendcobr_situacao == 'Aberto') headers.unshift({ text: '', value: '' })
      
      return headers
    },

   
  },

  methods: {
   
    getDescricaoPorCodigo(codigo) {
      const pagamento = this.array_formas_pagamento.find(pagamento => pagamento.cod_fin_tipo_pagamento === codigo);
      return pagamento ? pagamento.tipopagamento_descricao : '';
    },

    async excluirFormaDePagamento () {
      this.loading_excluir_forma_pagto = true
      try {
        let i = this.dados_editar.paymentTableData.indexOf(this.selectedFormaDePagamento);
        if (i !== -1) {
          this.dados_editar.paymentTableData.splice(i, 1);
          // console.log("🚀 this.dados_editar.paymentTableData:", this.dados_editar.paymentTableData)
          // console.log('1111',this.dados_editar.empreendcobr_pagto_valor)
          
          // console.log('222',parseFloat(this.selectedFormaDePagamento.empreendcobrpagto_valor))

          this.dados_editar.empreendcobr_pagto_valor = this.dados_editar.empreendcobr_pagto_valor - parseFloat(this.selectedFormaDePagamento.empreendcobrpagto_valor)
          this.dados_editar.empreendcobr_pagto_valor = parseFloat(this.dados_editar.empreendcobr_pagto_valor)
          this.key_grade_formapagamento++;
        }

        this.$emit('atualizaRegistro', this.dados_editar)
        this.loading_excluir_forma_pagto = false 
        this.modalExcluirFormaDePagamento = false
      } catch (err) {
        console.log(err)
      }
    },

    openModalExcluirFormaDePagamento (item) {
      this.selectedFormaDePagamento = item
      // this.dados_editar.empreendcobrpagto_valor - item.empreendcobrpagto_valor
     
      // console.log("🚀 ~ file: ContasReceberModal.vue:995 ~ openModalExcluirFormaDePagamento ~ this.selectedFormaDePagamento:", this.selectedFormaDePagamento)
      this.modalExcluirFormaDePagamento = true
    },
   
    atualizaArrayPagador(data){
      this.pagadorObj.pessoa_contas.forEach((conta, indexConta) => {
        conta.fin_cheque.forEach((cheque, indexCheque) => {
          data.cheques.forEach(dataCheque => {
            if (dataCheque.cod_fin_cheque === cheque.cod_fin_cheque) {
              cheque = dataCheque;
              this.$set()
            }
          });
        });
      });
    },

    saveFormaDePagamento(item) {
      // console.log("🚀 item:", item)

      let cheque_repetido = 0
      if(item.cod_fin_cheque > 0){
        this.dados_editar.paymentTableData.map(value => {
          if(value.cod_fin_cheque == item.cod_fin_cheque){
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = 'Este cheque já foi utilizado anteriormente!';
            this.store_site.alert           = true;
            cheque_repetido ++
            return
          }
        })
      }
      if (cheque_repetido == 0) {
        this.dados_editar.paymentTableData.push(item);
        this.key_grade_formapagamento++;
        
        this.dados_editar.empreendcobr_pagto_valor   = this.dados_editar.paymentTableData.reduce((total, item) => total + (parseFloat(item.empreendcobrpagto_valor)), 0);


        // this.dados_editar.empreendcobr_pagto_valor = this.dados_editar.paymentTableData.reduce((curr, prev) => 
        // console.log(curr)
        // , 0);
        // console.log("empreendcobr_pagto_valor:", this.dados_editar.empreendcobr_pagto_valor)
        // dados_editar.empreendcobr_pagto_valor
      }
    }, 

    toggleDialogFormasPagamento (item) {
      this.selectedCobranca = item
      this.dialogFormasPagamento = !this.dialogFormasPagamento
    },

    fechar() {
      this.$emit('update:dialog_novo_editar', false);
    },

    recalculaMultaJuros(parc_valor, dt_parc, dt_pagto, juros, multa) {
      //Calculo de Multa e Juros com base na contagem de dias entre a data de vencimento e data de pagamento, que será hoje por default
      const diff = this.calculaDiasDiferenca(dt_pagto, dt_parc);
      // console.log('diff',diff)
      if (diff > 0) {
        let calculo_juros = +(parseFloat(parc_valor) * (juros/100)).toFixed(2) * diff; // to fixed limita as casas decimais, mas transforma em string o '+' mantem como Float
        let calculo_multa = +(parseFloat(parc_valor) * (multa/100)).toFixed(2);  // to fixed limita as casas decimais, mas transforma em string o '+' mantem como Float
        this.dados_editar.empreendcobr_pagto_valor_juros = calculo_juros;
        this.dados_editar.empreendcobr_pagto_valor_multa = calculo_multa;                                           
      } 
      else {
        this.dados_editar.empreendcobr_pagto_valor_juros = null;
        this.dados_editar.empreendcobr_pagto_valor_multa = null;
      }
    },
    
    recalculaValorFinal(parc_valor, juros_calculado, multa_calculado, acrescimo, desconto) {
      //Recalculo do valor final nos juros e multa previamente calculados
      !juros_calculado  ? juros_calculado = 0 : juros_calculado;
      !multa_calculado  ? multa_calculado = 0 : multa_calculado;
      !acrescimo        ? acrescimo       = 0 : acrescimo;
      !desconto         ? desconto        = 0 : desconto;
      var pagamento_total =    parseFloat(parc_valor) +
                               parseFloat(acrescimo) - 
                               parseFloat(desconto) + 
                               parseFloat(juros_calculado) + 
                               parseFloat(multa_calculado); 
      this.dados_editar.empreendcobr_parc_valor_final = parseFloat(pagamento_total);
      this.dados_editar.empreendcobr_pagto_valor = this.dados_editar.paymentTableData.reduce((total, item) => total + (parseFloat(item.empreendcobrpagto_valor)), 0)
      return this.dados_editar
    },

    calculaDiasDiferenca (data, data2) {
      // Calcular diferença de dias entre uma data e outra
      let data_pagto = data;
      let data_parc =  new Date(data2);
      const dataSplit = data_pagto?.split('/')
      const dia = dataSplit[0]
      const mes = dataSplit[1]
      const ano = dataSplit[2]
      data_pagto = new Date(ano,mes-1,dia) 
      let diff = (data_pagto - data_parc)/(1000*60*60*24)
      let fim_semana = data_parc.getDay()
      if (fim_semana == 6) diff -= 2 //caso venc. seja sábado computa-se menos dois dias de juros
      if (fim_semana == 0) diff -= 1 //caso venc. seja domingo computa-se menos um dia de juros
      return diff
    },

    // Cria JSON com dados de pagamento
    pagar(){
      this.dados_editar.paymentTableData.forEach(val => {
        val.cod_fin_tipo_pagamento = val.tipo_pagamento.cod_fin_tipo_pagamento
        delete val.cod_pessoaconta
        // delete val.tipo_pagamento
        // console.log('tp', val.tipo_pagamento)
      })
      // console.log('dados',this.dados_editar);
      // return
      const lo_JSON = { cod_empreendcobranca            : this.dados_editar.cod_empreendcobranca,
                        empreendcobr_pagto_dt           : this.dados_editar.empreendcobr_pagto_dt, //.split('/').reverse().join('-'),
                        empreendcobr_situacao           : "Pago",
                        empreendcobr_pagto_valor_juros  : this.dados_editar.empreendcobr_pagto_valor_juros || null,
                        empreendcobr_pagto_valor_multa  : this.dados_editar.empreendcobr_pagto_valor_multa || null,
                        empreendcobr_parc_acres_valor   : this.dados_editar.empreendcobr_parc_acres_valor || null,
                        empreendcobr_parc_desc_valor    : this.dados_editar.empreendcobr_parc_desc_valor || null,
                        empreendcobr_pagto_valor        : this.dados_editar.empreendcobr_pagto_valor || null,
                        empreendcobr_parc_valor_final   : this.dados_editar.empreendcobr_parc_valor_final || null,
                        empreendcobr_parc_acres_obs     : this.dados_editar.empreendcobr_parc_acres_obs,
                        empreendcobr_parc_desc_obs      : this.dados_editar.empreendcobr_parc_desc_obs,
                        empreendcobr_obs                : this.dados_editar.empreendcobr_obs,
                        acao                            : this.dados_editar.acao,
                        empreendcobr_parc_ant_desc      : this.dados_editar.empreendcobr_parc_ant_desc,
                        empreendcobr_parc_ant_valor     : this.dados_editar.empreendcobr_parc_ant_valor,
                        empreendcobr_parc_ant_dt        : this.dados_editar.empreendcobr_parc_ant_dt,
                        empreendcobr_parc_ant_dt_vcto   : this.dados_editar.empreendcobr_parc_ant_dt_vcto,
                        empreendcobr_parc_ant_boleto    : this.dados_editar.empreendcobr_parc_ant_boleto,
                        formas_pagamento                : this.dados_editar.paymentTableData
                      }
      return lo_JSON
    },

    // Cria JSON com dados de edição
    editar(){
      const lo_JSON = { cod_empreendcobranca            : this.dados_editar.cod_empreendcobranca,
                        empreendcobr_parc_dt            : this.dados_editar.empreendcobr_parc_dt,
                        empreendcobr_parc_acres_valor   : this.dados_editar.empreendcobr_parc_acres_valor,
                        empreendcobr_parc_acres_obs     : this.dados_editar.empreendcobr_parc_acres_obs,
                        empreendcobr_parc_desc_obs      : this.dados_editar.empreendcobr_parc_desc_obs,
                        empreendcobr_parc_desc_valor    : this.dados_editar.empreendcobr_parc_desc_valor,
                        // empreendcobr_aplica_amortizacao : this.dados_editar.empreendcobr_aplica_amortizacao,
                        // empreendcobr_aplica_reajuste    : this.dados_editar.empreendcobr_aplica_reajuste,
                        empreendcobr_obs                : this.dados_editar.empreendcobr_obs,
                        empreendcobr_parc_valor_final   : this.dados_editar.empreendcobr_parc_valor,
                        acao                            : this.dados_editar.acao,
                      }
      return lo_JSON
    },

    novo(){
      const lo_JSON = { cod_empreendcobranca            : this.dados_editar.cod_empreendcobranca,
                        empreendcobr_parc_dt            : this.dados_editar.empreendcobr_parc_dt,
                        empreendcobr_parc_acres_valor   : this.dados_editar.empreendcobr_parc_acres_valor,
                        empreendcobr_parc_acres_obs     : this.dados_editar.empreendcobr_parc_acres_obs,
                        empreendcobr_parc_desc_obs      : this.dados_editar.empreendcobr_parc_desc_obs,
                        empreendcobr_parc_desc_valor    : this.dados_editar.empreendcobr_parc_desc_valor,
                        empreendcobr_aplica_amortizacao : this.dados_editar.empreendcobr_aplica_amortizacao,
                        empreendcobr_aplica_reajuste    : this.dados_editar.empreendcobr_aplica_reajuste,
                        empreendcobr_obs                : this.dados_editar.empreendcobr_obs,
                        acao                            : this.dados_editar.acao,       
      }
      return lo_JSON
    },

    async buscaProposta(item){
      const ls_Res = await this.store_ContasReceber.ContasReceberBuscaProposta(item);
      this.dados_proposta = ls_Res;
    },

    async validacoes(){
      this.dados_editar.empreendcobr_pagto_valor   = this.dados_editar.paymentTableData.reduce((total, item) => total + (parseFloat(item.empreendcobrpagto_valor)), 0);

      if(this.dados_editar?.empreendcobr_pagto_valor < this.dados_editar?.empreendcobr_parc_valor_final){
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'O valor total das formas de pagamento não podem ser inferiores ao Valor Final!';
        this.store_site.alert           = true;
        return 
      }
      
      if (!!this.dados_editar.empreendcobr_parc_acres_obs && !this.dados_editar.empreendcobr_parc_acres_valor ) {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Quando a Descrição Acréscimo estiver preenchida o Valor Acréscimo é obrigatório!';
        this.store_site.alert           = true;
        return 
      }
      
      if (!this.dados_editar.empreendcobr_parc_acres_obs && !!this.dados_editar.empreendcobr_parc_acres_valor ) {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Descrição Acréscimo é obrigatório caso haja valor!';
        this.store_site.alert           = true;
        return 
      }

      if (!!this.dados_editar.empreendcobr_parc_desc_obs && !this.dados_editar.empreendcobr_parc_desc_valor ) {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Quando a Descrição Desconto estiver preenchida o Valor Desconto é obrigatório!';
        this.store_site.alert           = true;
        return 
      }
      
      if (!this.dados_editar.empreendcobr_parc_desc_obs && !!this.dados_editar.empreendcobr_parc_desc_valor ) {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Descrição Desconto é obrigatório caso haja valor!';
        this.store_site.alert           = true;
        return 
      }

      return true
          
    },

    async validate () {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido || this.dados_editar.acao == 'EDITAR') {

        this.store_Financeiro.docFinanceiro = 1;
        this.store_Financeiro.dialogContasReceber = false;

        //Caso pagamento
        if (this.dados_editar.acao == 'PAGAR') {
          var validacoes = await this.validacoes()
          if(!validacoes){
            // console.log("🚀 ~ if:", !validacoes)
            return
          }
          console.log('passou direto');
          var lo_JSON = this.pagar()
          console.log("🚀 ~ file: ContasReceberModal.vue:1075 ~ validate ~ lo_JSON:", lo_JSON)
          return
          let ls_Res = null
          ls_Res = await this.store_ContasReceber.ContasReceberPagar(lo_JSON);
          // console.log("🚀 ~ file: ContasReceberModal.vue:1149 ~ validate ~ ls_Res:", ls_Res)
          // Mensagem de confirmação ou erro da operação Salvar
          if (ls_Res.status == 200) {
            this.$emit('atualizaRegistro', lo_JSON);
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
          } else {
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
          

        }
        //Caso edição, para visualização não há opção de salvar disponível
        else {
          var lo_JSON = this.editar()
          let ls_Res = null
          ls_Res = await this.store_ContasReceber.ContasReceberEditar(lo_JSON);
          if (ls_Res.status == 200) {
            this.$emit('atualizaRegistro', lo_JSON);

            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
          } else {
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
        }

        }

        //const dados_alterar = this.dados_editar
        this.$emit('update:dialog_novo_editar', false);
      //}
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  font-size: 19px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.title-page-menor {
  font-family: "Open Sans", sans-serif!important;
  font-size: 16px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  /* height: 700px!important; */
  overflow-y: hidden;
}

.row-value {
  margin-top: -146px;
}

.btn {
  width: 100px;
}

.v-divider {
  background: var(--COR_PRINCIPAL)!important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

</style>

<style scoped lang="scss">

    .input-text {
        ::v-deep {
            .v-text-field {
                input {
                    font-size: 19px;
                }
            }
        }
    }
</style>