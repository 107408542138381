<template>
  <div id="formasDePagamentoModal" class="overflow-x-hidden">
    <v-card class="formas-de-pagamento container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between pt-3 pb-2 px-3">
        <span class="white--text title-page body-1">Formas de Pagamento - Restante: R$ {{ valorTroco ? formatPrice(valorTroco) : 0 }}</span>
          <v-btn @click="fechar" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="wrapper px-0">
        <div>
          <v-row class="mt-6 px-5">
            <v-col>
              <v-form ref="form" v-model="valid">
                <fieldset class="d-flex flex-column rounded mb-2" style="border: none">
                  <v-row>
                    <!-- <pre>{{ formaDePagamentoForm.cod_fin_tipo_pagamento }}</pre> -->
                    <v-col col="12" class="px-2">
                      <v-select
                        v-model="formaDePagamentoForm.tipo_pagamento"
                        label="Forma de pagamento"
                        hide-details
                        filled
                        dense
                        outlined
                        @change="preencheDescricao"
                        background-color="#FFF"
                        :items="array_formas_pagamento"
                        return-object
                        item-key="cod_fin_tipo_pagamento"
                        item-text="tipopagamento_descricao"
                        item-value="tipopagamento_descricao" />
                    </v-col>
                  </v-row>

                  <div v-if="formaDePagamentoForm.tipo_pagamento?.cod_fin_tipo_pagamento == 1 || formaDePagamentoForm.tipo_pagamento?.cod_fin_tipo_pagamento == 3 ">
                    <h3 class="primary--text text-center py-4">Conta de Destino</h3>
                    <v-row >
                      <v-col col="12" class="px-2">
                        <v-select
                          v-model="formaDePagamentoForm.cod_pessoaconta"
                          label="Conta Destino"
                          filled
                          item-text="pessoa_conta_descricao"
                          item-key="cod_pessoa_conta"
                          dense
                          return-object
                          outlined
                          background-color="#FFF"
                          :items="opcoes_caixa_interno"
                          :rules="notEmptySelectRule"
                          />
                      </v-col>
                    </v-row>
                  </div>
                  
                  <!-- <div v-if="formaDePagamentoForm.cod_fin_tipo_pagamento == 8">
                    <h3 class="primary--text text-center py-4">Conta de Origem</h3>
                    <v-row >
                      <v-col col="12" class="px-2">
                        <v-select
                          v-model="formaDePagamentoForm.cod_pessoaconta"
                          label="Selecione o cartão"
                          hide-details
                          filled
                          id="selecione_cartao"
                          dense
                          @change="changeCartaoCredito()"
                          outlined
                          item-text="pessoa_conta_descricao"
                          item-key="cod_pessoa_conta"
                          background-color="#FFF"
                          return-object
                          :items="opcoes_cartao_credito"
                          />
                      </v-col>
                    </v-row>
                  </div> -->

                  <!-- INPUTS DINAMICOS - CONTROLADOS PELO TIPO DE PAGAMENTO -->
                  <!-- CHEQUE PRÓPRIO / CHEQUE TERCEIROS-->
                  <div v-if="formaDePagamentoForm.tipo_pagamento?.cod_fin_tipo_pagamento == 3">
                    <v-row class="px-2 mt-5 align-center">
                      <br>
                      <v-autocomplete
                        v-model="formaDePagamentoForm.cod_fin_cheque"
                        item-value="cod_fin_cheque"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        :items="chequeOptions"
                        label="Selecione um cheque *"
                        item-text="text"
                        item-key="cod_fin_cheque"
                        id="selecione_cheque"
                        :rules="notEmptySelectRule"
                        @change="setSelectedCheque"
                        clearable
                        :loading="isChequeLoading" />
                      <v-icon
                        class="pointer ml-2 mb-7"
                        style="cursor: pointer"
                        color="green accent-2"
                        @click="toggleChequeModal('novo')">
                        mdi-plus
                      </v-icon>
                    </v-row>
                    <v-row class="px-2 mt-6 mb-5">
                      <fieldset class="d-flex flex-column rounded pt-2 pb-5 px-3" style="border: 1px solid #cecece; width: 100%">
                        <legend class="primary--text">Cheque</legend>
                        <v-row class="justify-end">
                          <v-btn
                            :disabled="!formaDePagamentoForm?.cod_fin_cheque"
                            @click="toggleChequeModal"
                            class="mr-4 btn caption font-weight-medium"
                            color="primary"
                            text>
                            <v-icon
                              class="mr-1"
                              size="16"
                              color="red accent-2">
                              mdi-pencil
                            </v-icon>
                            <span>Editar</span>
                          </v-btn>
                        </v-row>
                        <v-row class="px-2">
                          <v-col cols="12" class="px-2">
                            <v-text-field
                              hide-details
                              v-model="formaDePagamentoForm.empreendcobrpagto_titular_dest"
                              filled
                              readonly
                              dense
                              outlined
                              background-color="#FFF"
                              placeholder="Titular"
                              label="Titular" />
                          </v-col>
                        </v-row>
                        <v-row class="px-2">
                          <v-col cols="8" class="px-2">
                            <v-text-field v-model="formaDePagamentoForm.empreendcobrpagto_banco_dest" hide-details filled readonly dense outlined background-color="#FFF" placeholder="Banco" label="Banco" />
                          </v-col>
                          <v-col cols="4" class="px-2">
                            <v-text-field v-model="formaDePagamentoForm.empreendcobrpagto_agencia_dest" hide-details filled readonly dense outlined background-color="#FFF" placeholder="Agência" label="Agência" />
                          </v-col>
                        </v-row>
                        <v-row class="px-2">
                          <v-col cols="8" class="px-2">
                            <v-text-field v-model="formaDePagamentoForm.empreendcobrpagto_conta_dest" hide-details filled readonly dense outlined background-color="#FFF" placeholder="Conta" label="Conta" />
                          </v-col>
                          <v-col cols="4" class="px-2">
                            <v-text-field v-model="formaDePagamentoForm.empreendcobrpagto_numero_cheque" hide-details filled readonly dense outlined background-color="#FFF" placeholder="N. Cheque" label="N. Cheque" />
                          </v-col>
                        </v-row>
                      </fieldset>
                    </v-row>
                  </div>

                  <!-- TRANSFERÊNCIAS BANCÁRIAS (TRANSFERÊNCIAS, PIX, DEPÓSITO, CARTÕES) -->
                  <div>
                    <div v-if="showContaDeOrigem">
                      <h3 class="primary--text text-center py-4">Conta de Origem</h3>
                      <!-- <pre>{{ array_opcoes_contas }}</pre> -->
                      <v-row class="px-2">
                        <v-col class="px-0 pb-0">
                          <v-select
                            v-model="formaDePagamentoForm.cod_pessoaconta"
                            filled
                            dense
                            :rules="notEmptySelectRule"
                            outlined
                            id="conta_origem"
                            background-color="#FFF"
                            :items="array_opcoes_contas"
                            label="Selecione a conta *"
                            item-text="text"
                            item-key="id"
                            required
                            @change="setDadosConta" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" class="px-2">
                          <v-text-field
                            v-model="bancoOrigem"
                            readonly
                            filled
                            dense
                            outlined
                            background-color="#FFF"
                            :rules="notEmptyRule"
                            placeholder="Banco"
                            label="Banco" />
                        </v-col>
                        <v-col cols="4" class="px-2">
                          <v-text-field v-model="agenciaOrigem" readonly filled dense outlined background-color="#FFF" :rules="notEmptyRule" placeholder="Agência" label="Agência" />
                        </v-col>
                        <v-col cols="4" class="px-2">
                          <v-text-field v-model="contaOrigem" readonly filled dense outlined background-color="#FFF" :rules="notEmptyRule" placeholder="Conta" label="Conta" />
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="showContaDeDestino" class="mb-n10">
                      <h3 class="primary--text text-center py-4">Conta de Destino</h3>
                      <v-row v-if="isPix">
                        <v-col cols="12" class="px-2 mb-n5">
                          <v-text-field
                            v-model="formaDePagamentoForm.empreendcobrpagto_chave_pix"
                            :rules="notEmptyRule"
                            filled
                            dense
                            outlined
                            background-color="#FFF"
                            placeholder="Chave Pix *"
                            label="Chave Pix *" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="px-2 mt-n0">
                          <v-text-field
                            v-model="formaDePagamentoForm.empreendcobrpagto_titular_dest"
                            :rules="notEmptyRule"
                            filled
                            dense
                            outlined
                            id="titular_dest"
                            maxlength="80"
                            background-color="#FFF"
                            placeholder="Titular *"
                            label="Titular *" />
                        </v-col>
                      </v-row>
                      <v-row class="mt-n3">
                        <v-col cols="4" class="px-2 mt-n3">
                          <v-text-field
                            v-model="formaDePagamentoForm.empreendcobrpagto_banco_dest"
                            :rules="notEmptyRule"
                            filled
                            dense
                            outlined
                            background-color="#FFF"
                            maxlength="20"
                            placeholder="Banco *"
                            label="Banco *" />
                        </v-col>
                        <v-col cols="4" class="px-2 mt-n3">
                          <v-text-field
                            v-model="formaDePagamentoForm.empreendcobrpagto_agencia_dest"
                            :rules="notEmptyRule"
                            filled
                            dense
                            outlined
                            background-color="#FFF"
                            maxlength="20"
                            placeholder="Agência *"
                            label="Agência *" />
                        </v-col>
                        <v-col cols="4" class="px-2 mt-n3">
                          <v-text-field
                            v-model="formaDePagamentoForm.empreendcobrpagto_conta_dest"
                            :rules="notEmptyRule"
                            filled
                            dense
                            outlined
                            background-color="#FFF"
                            maxlength="20"
                            placeholder="Conta *"
                            label="Conta *" />
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <!-- FIM INPUTS DINAMICOS -->
                  <v-row v-if="isCartaoDeCredito" class="px-2 mt-2">
                    <v-col class="px-0">
                      <v-text-field
                        v-model="formaDePagamentoForm.empreendcobrpagto_num_cartao"
                        :rules="notEmptyRule"
                        filled
                        dense
                        readonly
                        outlined
                        background-color="#FFF"
                        placeholder="Nº Do Cartão *"
                        label="Nº Do Cartão *" />
                    </v-col>
                  </v-row>
                
                  <v-row class="mt-3">
                    <!-- {{ formaDePagamentoForm.tipo_pagamento_cod_fin_tipo_pagamento }} -->
                    <v-col col="12" class="px-2">
                      <v-textarea
                        v-model="formaDePagamentoForm.empreendcobrpagto_descr"
                        rows="2"
                        background-color="#FFF"
                        label="Descrição"
                        placeholder="Descrição"
                        filled
                        dense
                        outlined
                        maxlength="100"
                        counter="100" />
                    </v-col>
                  </v-row >
                  <v-row class="justify-end px-2 mt-n2">
                    <VuetifyMoney
                      type="tel"
                      v-model="formaDePagamentoForm.empreendcobrpagto_valor"
                      filled
                      dense
                      outlined
                      background-color="#FFF"
                      placeholder="Valor *"
                      label="Valor *"
                      ref="vuetifyMoneyRef"
                      :clearable="!isCheque"
                      :readonly="isCheque"
                      reverse
                      required
                      :rules="notEmptyRule"
                      class="font-weight-bold formas-de-pagamento__valor-input" />
                  </v-row>
                </fieldset>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-footer color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
          @click="fechar"
          class="mr-4 btn caption font-weight-medium"
          color="primary"
          text>Cancelar</v-btn>
        <v-btn
          @click="validate"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4"
          :loading="isSaveButtonLoading"
          :disabled="isSaveButtonDisabled">Salvar</v-btn>
      </v-footer>
    </v-card>
  
    <v-container v-if="isChequeModalOpen">
      <v-dialog
        v-model="isChequeModalOpen"
        transition="dialog-bottom-transition"
        max-width="600"
        min-width="520"
        class="overflow-x-hidden">
        <ChequeNovoModal
          v-if="isChequeModalOpen"
          class="mt-n2"
          :editar="editCheque"
          :pagador="array_contas_original"
          :selectedCheque="selectedCheque"
          :isChequeTerceiro="isChequeTerceiro"
          :isChequeModalOpen.sync="isChequeModalOpen"
          @chequeCriado="handleChequeCriado"
          @chequeEditado="handleChequeEditado" />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import store_site from "../../../../src/store/store_site"
import { API } from "../../../services/API"
import moment, { now } from 'moment';
import { formatPrice } from '../../../services/funcoes'
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO, MASK_CPFCNPJ, MASK_TELEFONE, } from "../../../services/constantes";
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import ChequeNovoModal from './ChequeNovoModal.vue'
import store_ContasPagar from "../ContasPagar/store_ContasPagar"

var data_AUX;

export default {
  components: {
    VuetifyMoney,
    ChequeNovoModal,
    
  },

  props: ['dialogFormasPagamento', 'pagador', 'dados_cobranca', 'valorTroco', 'cheques_escolhidos'],

  data () {
    return {
      store_ContasPagar : store_ContasPagar,
      chequesList: [],
      isChequeLoading: false,
      store_site: store_site,
      editCheque: false,
      valid: true,
      selectedCheque: {},
      array_cheques : [],
      array_opcoes_contas   : [],
      caixa_interno         : 'CAIXA INTERNO',
      array_contas_original : [],
      opcoes_caixa_interno  : [],
      formatPrice   : formatPrice,
      formaDePagamentoForm: {
        cod_empreendcobranca: null,
        // cod_fin_tipo_pagamento: 1,
        // tipoPagamento: '',
        empreendcobrpagto_descr: '',
        empreendcobrpagto_valor: 0,
        cod_fin_cheque: null,
        cod_pessoaconta: null,
        empreendcobrpagto_titular_dest: '',
        empreendcobrpagto_chave_pix: '',
        empreendcobrpagto_num_cartao: '',
        empreendcobrpagto_dt_cadastro : moment().format(), 
        empreendcobrpagto_banco_dest : '', 
        empreendcobrpagto_agencia_dest : '', 
        empreendcobrpagto_conta_dest : '',
        empreendcobrpagto_tipo_oper : 'R'
      },
      bancoOrigem: '',
      agenciaOrigem: '',
      contaOrigem: '',
      isSaveButtonLoading: false,
      isChequeModalOpen: false,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SUBTITULO     : COR_SUBTITULO,  
      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,

      array_formas_pagamento : [
        { cod_fin_tipo_pagamento : 1, tipopagamento_descricao: 'DINHEIRO' },
        { cod_fin_tipo_pagamento : 3, tipopagamento_descricao: 'Cheque de Terceiros' },
        { cod_fin_tipo_pagamento : 6, tipopagamento_descricao: 'Depósito' },
        { cod_fin_tipo_pagamento : 5, tipopagamento_descricao: 'PIX' },
        { cod_fin_tipo_pagamento : 4, tipopagamento_descricao: 'Transferencia (DOC, TED, Internet, etc)' },
      ],
    
      opcoes_cartao_credito : [],

      notEmptyRule: [value => value?.length > 0  || 'Esse campo é obrigatório'],
      notEmptySelectRule: [ value => !!value || 'Esse campo é obrigatório']
    }
  },

  watch: {
    // 'formaDePagamentoForm.tipo_pagamento'(){
    //   if(this.formaDePagamentoForm.tipo_pagamento_cod_fin_tipo_pagamento === 1 || 
    //      this.formaDePagamentoForm.tipo_pagamento_cod_fin_tipo_pagamento === 3 ){
    //     this.formaDePagamentoForm.cod_pessoaconta = this.opcoes_caixa_interno[0]
    //     this.formaDePagamentoForm.empreendcobrpagto_descr = 'CAIXA INTERNO'
    //     console.log("🚀 ~ watch", this.formaDePagamentoForm)
    //   }
    // }

      // esta parte é para dar o focu no campo dependendo de cada forma de pagamento
      // if(this.formaDePagamentoForm.cod_fin_tipo_pagamento === 1){
      //   let valor = document.querySelector('#valor')
      //   console.log("🚀 ~ file: FormasDePagamentoModal.vue:466 ~ valor:", valor)
      //   // this.$refs.valor.focus();
      //   // valor.focus()
      // }

    // },
    // 'formaDePagamentoForm.cod_fin_tipo_pagamento': {
    //   handler(newVal) {
    //     if (newVal === 1) {
    //       this.$nextTick(() => {
    //         this.$refs.valorDinheiroRef.$el.focus();
    //         console.log("🚀 ~ file: FormasDePagamentoModal.vue:478 ~ this.$nextTick ~ this.$refs.valorDinheiroRef:", this.$refs.valorDinheiroRef)
    //       });
    //     }
    //   },
    //   immediate: true
    // }
  },

  computed: {
    chequeOptions () {
      if (!this.chequesList.length) 
        return []
      return this.chequesList.map(cheque => ({
        cod_fin_cheque: cheque.cod_fin_cheque, 
        value: cheque.cod_fin_cheque, 
        text: `${cheque.banco_compensacao} - ${cheque.banco_nome} - ${cheque.fin_cheque_agencia} - ${cheque.fin_cheque_conta} - R$${formatPrice(cheque.fin_cheque_valor)}` 
      }))
    },

    isCheque () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 2 
        || this.formaDePagamentoForm.cod_fin_tipo_pagamento === 3
    },

    isChequeTerceiro () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 3
    },

    showAddChequeIcon () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 2
    },
    
    isTransferencia () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 4
    },  

    isPix () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 5
    },

    isDeposito () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 6
    },

    isCartaoDeDebito () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 7
    },

    isCartaoDeCredito () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 8
    },

    isBoleto () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 9
    },

    isCarne () {
      return this.formaDePagamentoForm.cod_fin_tipo_pagamento === 10
    },

    showContaDeOrigem () {
      return this.isTransferencia || this.isPix || this.isCartaoDeDebito || this.isBoleto || this.isCarne
    },  

    showContaDeDestino () {
      return this.isTransferencia || this.isPix || this.isDeposito
    },

    isSaveButtonDisabled () {
      return this.formaDePagamentoForm.empreendcobrpagto_valor == 0 || !this.valid
    }
  },
  
  async mounted() {
    // this.formaDeRecebimentoForm = {
    //   ...this.dados_editar
    // }
    // console.log(this.valorTroco);
    this.formaDePagamentoForm.tipo_pagamento = {
      cod_fin_tipo_pagamento  : 1,
      tipopagamento_descricao : 'DINHEIRO'
    }
    // console.log("🚀 ~ file: FormasDePagamentoModal.vue:558 ~ mounted ~ this.formaDePagamentoForm.tipo_pagamento.cod_fin_tipo_pagamento:", this.formaDePagamentoForm.tipo_pagamento.cod_fin_tipo_pagamento)
    await this.opcoesContas()
    await this.getChequesList()
    this.formaDePagamentoForm.empreendcobrpagto_descr = 'CAIXA INTERNO'

    this.$nextTick(() => {
      const inputElement = this.$refs.vuetifyMoneyRef.$el.querySelector('input');
      if (inputElement) {
        inputElement.focus();
      }
    });
  },

  methods: {
    changeCartaoCredito(){
      this.formaDePagamentoForm.empreendcobrpagto_num_cartao = this.formaDePagamentoForm.cod_pessoaconta.pessoa_conta_numero
      this.formaDePagamentoForm.empreendcobrpagto_descr = this.formaDePagamentoForm.cod_pessoaconta.pessoa_conta_descricao
    },

    async opcoesContas () {
      // console.log("🚀 opcoesContas11111:", this.dados_cobranca)
      const {data} = await API.get('/conta_pessoa/',
      { 
        // params: { cod_pessoa_conta : this.dados_cobranca.empreend_venda.vendas.cod_pessoa_conta } 
      })
      let conta_empreendedor = data.result.find(value => value.cod_pessoa_conta == this.dados_cobranca.empreend_venda.vendas.cod_pessoa_conta)

      let conta_interna = data.result.find(elem => elem.pessoa_conta_descricao === 'CAIXA INTERNO' && elem.cod_pessoa === conta_empreendedor.cod_pessoa)
      // array_conta_dinheiro.push([...conta_interna])
      this.opcoes_caixa_interno = [conta_interna]
      this.formaDePagamentoForm.cod_pessoaconta = this.opcoes_caixa_interno[0]
      // console.log("🚀 ~ conta_empreendedor:", conta_empreendedor)
      // let array_contas = data.result.forEach(elem => elem.cod_pessoa === conta_empreendedor.cod_pessoa)
      // console.log("🚀 data11:", array_contas)
      // this.array_opcoes_contas = data?.result
      // console.log("🚀 ~ file: FormasDePagamentoModal.vue:579 ~ opcoesContas ~ this.array_opcoes_contas:", this.array_opcoes_contas)

      // var array_contas_encontradas = []
      // var array_conta_dinheiro = []
      // var array_cartao_credito = []
      
      // data?.result.forEach((elemento) => {
      //   if (elemento.pessoa_conta_tipo === "B") {
      //     array_contas_encontradas.push(elemento);
      //   }
      //   if (elemento.pessoa_conta_tipo === "D") {
      //     array_conta_dinheiro.push(elemento);
      //   }
      //   if (elemento.pessoa_conta_tipo === "CC") {
      //     array_cartao_credito.push(elemento);
      //   }
       
      // });
      
      // this.opcoes_caixa_interno = array_conta_dinheiro
      // this.formaDePagamentoForm.cod_pessoaconta = this.opcoes_caixa_interno[0]

      // this.opcoes_cartao_credito = array_cartao_credito
      // this.array_contas_original = array_contas_encontradas
      // this.array_opcoes_contas = array_contas_encontradas?.map(conta => {
      //   return {
      //           id: conta.cod_pessoa_conta, 
      //     text: `${conta.pessoa_conta_agencia.banco_compensacao} - 
      //            ${conta.pessoa_conta_agencia.banco_nome} - 
      //            ${conta.pessoa_conta_agencia.agencia_numero} - 
      //            ${conta.pessoa_conta_numero}${conta.pessoa_conta_numero_dig ? '-' + conta.pessoa_conta_numero_dig : ''}`, 
      //     value: conta.cod_pessoa_conta 
      //   }
      // })

      // return this.pessoaContas?.map(conta => {
      //   return { 
      //     id: conta.cod_pessoa_conta, 
      //     text: `${conta.pessoa_conta_agencia.banco_compensacao} - 
      //            ${conta.pessoa_conta_agencia.banco_nome} - 
      //            ${conta.pessoa_conta_agencia.agencia_numero} - 
      //            ${conta.pessoa_conta_numero}${conta.pessoa_conta_numero_dig ? '-' + conta.pessoa_conta_numero_dig : ''}`, 
      //     value: conta.cod_pessoa_conta 
      //   }
      // })
    },

    async getChequesList () {

      // let la_cod_pessoa_conta = []
      // this.array_contas_original?.map(elem => la_cod_pessoa_conta?.push(elem.cod_pessoa_conta))
      const {data} = await API.get('/erp/contasreceber_cheques/',{})
      this.chequesList = data.result.cheques
      this.cheques_escolhidos?.forEach(codigo => {
        const index = this.chequesList.findIndex(cheque => cheque.cod_fin_cheque === codigo);
        if (index !== -1) {
          this.chequesList.splice(index, 1);
        }
      });
    },

    preencheDescricao(){ 
      // this.formaDePagamentoForm.cod_empreendcobranca            = null
      // this.formaDePagamentoForm.cod_fin_tipo_pagamento          = 1
      this.formaDePagamentoForm.tipoPagamento                   = null
      this.formaDePagamentoForm.empreendcobrpagto_descr         = null
      this.formaDePagamentoForm.empreendcobrpagto_valor         = null
      this.formaDePagamentoForm.cod_fin_cheque                  = null
      this.formaDePagamentoForm.cod_pessoaconta                 = null
      this.formaDePagamentoForm.empreendcobrpagto_titular_dest  = ""
      this.formaDePagamentoForm.empreendcobrpagto_chave_pix     = ""
      this.formaDePagamentoForm.empreendcobrpagto_num_cartao    = ""
      this.formaDePagamentoForm.empreendcobrpagto_banco_dest    = "" 
      this.formaDePagamentoForm.empreendcobrpagto_agencia_dest  = "" 
      this.formaDePagamentoForm.empreendcobrpagto_conta_dest    = ""
      this.formaDePagamentoForm.empreendcobrpagto_numero_cheque = ""
      this.bancoOrigem = ''
      this.agenciaOrigem = ''
      this.contaOrigem = ''

      console.log("🚀 ~ file: FormasDePagamentoModal.vue:664 ~ preencheDescricao ~ this.formaDePagamentoForm:", this.formaDePagamentoForm)
      if (this.formaDePagamentoForm.tipo_pagamento.cod_fin_tipo_pagamento === 1 || this.formaDePagamentoForm.tipo_pagamento.cod_fin_tipo_pagamento === 3) {
        this.formaDePagamentoForm.cod_pessoaconta = this.opcoes_caixa_interno[0]
        this.formaDePagamentoForm.empreendcobrpagto_descr = 'CAIXA INTERNO'
      } else {
        this.formaDePagamentoForm.empreendcobrpagto_descr = this.formaDePagamentoForm.tipo_pagamento.text

      }
      this.$nextTick(() => {
        const inputElement = this.$refs.vuetifyMoneyRef.$el.querySelector('input');
        if (inputElement) {
          inputElement.focus();
        }
      });

      // var ls_descricao = null
      // this.opcoesFormaDePagamento.find(forma => {
      //   if(forma.id === this.formaDePagamentoForm.cod_fin_tipo_pagamento){
      //     ls_descricao = forma.text
      //   }
      // } )
      // this.formaDePagamentoForm.empreendcobrpagto_descr = ls_descricao
    
    },

    fechar() {
      this.selectedCheque = null
      this.formaDePagamentoForm = {
        cod_empreendcobranca: null,
        cod_fin_tipo_pagamento: 1,
        tipoPagamento: '',
        empreendcobrpagto_descr: '',
        empreendcobrpagto_valor: 0,
        cod_fin_cheque: null,
        cod_pessoaconta: 1,
        empreendcobrpagto_titular_dest: '',
        empreendcobrpagto_chave_pix: '',
        empreendcobrpagto_num_cartao: '',
        empreendcobrpagto_dt_cadastro : moment().format(), 
        empreendcobrpagto_banco_dest : '', 
        empreendcobrpagto_agencia_dest : '', 
        empreendcobrpagto_conta_dest : '',
        empreendcobrpagto_tipo_oper : 'R'
      }
      this.bancoOrigem = ''
      this.agenciaOrigem = ''
      this.contaOrigem = ''

      this.$emit('update:dialogFormasPagamento', false);
    },

    setDadosConta() {
      const selectedConta = this.array_contas_original.find(conta => {
        return conta.cod_pessoa_conta === this.formaDePagamentoForm.cod_pessoaconta
      })
      
      this.bancoOrigem = selectedConta.pessoa_conta_agencia.banco_nome
      this.agenciaOrigem = selectedConta.pessoa_conta_agencia.agencia_numero
      this.contaOrigem = selectedConta.pessoa_conta_numero

      this.formaDePagamentoForm.empreendcobrpagto_descr = `${selectedConta.pessoa_conta_agencia.banco_compensacao} - ${selectedConta.pessoa_conta_agencia.banco_nome} - ${selectedConta.pessoa_conta_agencia.agencia_numero} - ${selectedConta.pessoa_conta_numero}`
    },

    handleChequeCriado (cheque) {
      console.log("🚀 ~ file: FormasDePagamentoModal.vue:734 ~ cheque:", cheque)
      this.chequesList.push(cheque)
      this.formaDePagamentoForm.cod_fin_cheque = cheque.cod_fin_cheque
      this.selectedCheque = { ...cheque }
      this.getChequeData(cheque)
    },

    handleChequeEditado (cheque) {
      const index = this.chequesList.findIndex(({ cod_fin_cheque }) => cod_fin_cheque == cheque.cod_fin_cheque)
      this.selectedCheque = { ...cheque }

      this.getChequeData(cheque)

      this.$set(this.chequesList, index, cheque)
    },

    getChequeData (cheque) {
      if (!cheque) {
        this.formaDePagamentoForm.cod_fin_cheque = ''
        this.formaDePagamentoForm.empreendcobrpagto_banco_dest = ''
        this.formaDePagamentoForm.empreendcobrpagto_agencia_dest = ''
        this.formaDePagamentoForm.empreendcobrpagto_titular_dest = ''
        this.formaDePagamentoForm.empreendcobrpagto_numero_cheque = ''
        this.formaDePagamentoForm.empreendcobrpagto_conta_dest = ''
        this.formaDePagamentoForm.empreendcobrpagto_valor = ''

        return
      }

      const conta = this.array_contas_original.find(conta => {
        return conta.cod_pessoa_conta == cheque.cod_pessoaconta
      })
      this.formaDePagamentoForm.cod_fin_cheque = cheque.cod_fin_cheque
      this.formaDePagamentoForm.empreendcobrpagto_banco_dest = this.selectedCheque.banco_compensacao + ' - ' + this.selectedCheque.banco_nome
      this.formaDePagamentoForm.empreendcobrpagto_agencia_dest = this.selectedCheque.fin_cheque_agencia
      this.formaDePagamentoForm.empreendcobrpagto_titular_dest = this.selectedCheque.fin_cheque_titular
      this.formaDePagamentoForm.empreendcobrpagto_numero_cheque = this.selectedCheque.fin_cheque_numero
      this.formaDePagamentoForm.empreendcobrpagto_conta_dest = this.selectedCheque.fin_cheque_conta
      this.formaDePagamentoForm.empreendcobrpagto_valor = this.selectedCheque.fin_cheque_valor
    },

    setSelectedCheque (data) {
      this.selectedCheque = this.chequesList.find(({cod_fin_cheque}) => cod_fin_cheque == data)
      this.formaDePagamentoForm.empreendcobrpagto_descr = `${this.selectedCheque.banco_compensacao} - ${this.selectedCheque.banco_nome} - ${this.selectedCheque.fin_cheque_agencia} - ${this.selectedCheque.fin_cheque_conta}`
      this.getChequeData(this.selectedCheque)
    },

    async validate() {
      this.isSaveButtonLoading = true

      const tempForm = { ...this.formaDePagamentoForm }
      tempForm.cod_empreendcobranca = this.dados_cobranca?.cod_empreendcobranca
      tempForm.cod_fin_tipo_pagamento =  this.formaDePagamentoForm.tipo_pagamento.cod_fin_tipo_pagamento
      //   cod_fin_tipo_pagamento : this.formaDePagamentoForm.tipo_pagamento.cod_fin_tipo_pagamento,
      //   tipopagamento_descricao : this.formaDePagamentoForm.tipo_pagamento.tipopagamento_descricao
      // }
     
      if (tempForm.cod_fin_cheque > 0) {
      
        // tempForm.empreendcobrpagto_descr = `${this.selectedCheque.banco_compensacao} - 
        //                              ${this.selectedCheque.banco_nome} - 
        //                              ${this.selectedCheque.fin_cheque_agencia} - 
        //                              ${this.selectedCheque.fin_cheque_conta}`
        tempForm.empreendcobrpagto_agencia_dest = null
        tempForm.empreendcobrpagto_banco_dest = null
        tempForm.empreendcobrpagto_chave_pix = null
        tempForm.empreendcobrpagto_conta_dest = null
        // tempForm.empreendcobrpagto_descr = null
        tempForm.empreendcobrpagto_num_cartao = null
        tempForm.empreendcobrpagto_numero_cheque = null
        tempForm.empreendcobrpagto_tipo_oper = null
        tempForm.empreendcobrpagto_titular_dest = null
        tempForm.tipoPagamento = null
      }


      // this.formaDePagamentoForm.tipoPagamento = store_ContasPagar.opcoesFormaDePagamento.find(tipo => {
      //   return tipo.id == this.formaDePagamentoForm.cod_fin_tipo_pagamento
      // }).text

      this.isSaveButtonLoading = false
      this.$emit('criaFormaDePagamento', { ...tempForm })
     
      this.fechar();
    },

    toggleChequeModal (type = '') {
      if (type === 'novo') {
        this.chequeForm = {}
        this.selectedCheque = {}
        this.editCheque = false
      } else {
        this.editCheque = true  
      }
      this.isChequeModalOpen = !this.isChequeModalOpen
    }
  }
}
</script>

<style lang="scss">
.formas-de-pagamento {
  &__valor-input input {
    color: #388E3C !important;
  }
}
</style>

