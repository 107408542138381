<template>
  <div id="ContasReceberNovoModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
                    class="justify-space-between py-2 px-4">                  
          <!--Cabeçalho com busca para a ação novo -->
            <v-row>
              <v-col class="title-page" cols="4">Incluir Cobranças</v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="proposta_incluir"
                  filled
                  dense
                  outlined
                  autofocus
                  clearable
                  background-color="#FFF"
                  placeholder="Nº Proposta"
                  label="Nº Proposta"
                  maxlength="100"
                >
                </v-text-field>
              </v-col>
              <v-col> 
                <v-btn
                  class="text-white text-capitalize"
                  :disabled="!proposta_incluir"
                  :color="COR_PRINCIPAL"
                  @click="buscaProposta(proposta_incluir)"
                >
                  <v-icon large color="#FFF">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
                <v-btn
                  @click="fechar()"
                  icon
                  dark
                  color="#F2F6F7"
                  >
                    <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
            </v-row>
       </v-card-title>
       
      <v-card-text class="wrapper pa-0">
        <v-divider dark></v-divider>

        <div v-bind:style="{ 'background-color':COR_PRINCIPAL }">

          <!-- Cabecalho -------------------- -->
          <v-row class="ml-1 mt-0">
            <v-col>
              <label style="color:white;font-size:15px;" class="mr-4">{{dados_proposta.pessoa_nome}}</label>
            </v-col>
          </v-row>
          <v-row class="ml-1 mt-n5">
            <v-col>
              <label class="" style="color:silver;font-size:14px;">CPF / CNPJ: </label> <label style="color:white;font-size:15px;" class="mr-4">{{maskCpfCnpj(dados_proposta.cpf_cnpj)}}</label>
              <label style="color:silver;font-size:14px;">Telefone: </label> <label style="color:white;font-size:15px;">{{dados_editar.empreend_venda.pessoa_venda.fone_celular1}} {{dados_editar.empreend_venda.pessoa_venda.fone_celular2}}</label>
            </v-col>
          </v-row>
          <v-row class="ml-1 mt-n5">
            <v-col>
              <label style="color:silver;font-size:14px;font-style: bold;">Empreendimento: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_proposta.empreend_nome}}</label>
              <label style="color:silver;font-size:14px;">Quadra: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_proposta.quadra}}</label>
              <label style="color:silver;font-size:14px;">Lote: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_proposta.lote}}</label>
            </v-col>
          </v-row>
          <v-row class="ml-1 mt-n5">
            <v-col>
              <label style="color:silver;font-size:14px;font-style: bold;">Nº Proposta: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_proposta.cod_empreendvenda}}</label>
              <label style="color:silver;font-size:14px;">  Contrato: </label> <label style="color:white;font-size:15px;" class="mr-5">{{dados_proposta.empreendvenda_contrato}}</label>
              <label style="color:silver;font-size:14px;">  Data Contrato: </label> <label style="color:white;font-size:15px;">{{formatDate(dados_proposta.empreendvenda_contr_data)}}</label>
            </v-col>
          </v-row>   
        </div>

        <!-- Dados ---------------------- -->
        <div >
          <v-row class="mt-6 px-5">
            <v-col>
              <v-form 
              ref="form"
              v-model="valid">
                <fieldset class="d-flex flex-column rounded mb-2 " style="border: none">
                  <v-row class="mt-n5">
                    <v-col col="3" class="px-2">
                       <v-select
                        v-model="dados_proposta.tipo_parcela"
                        label="Tipo de Parcela *"
                        class="w-100 mb-n3"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        :items="opcoes_tipo_parcela"
                        :rules="requiredRules"
                        item-text="value"
                        item-key="id"
                      ></v-select>
                    </v-col>
                    <v-col cols="3" class="px-2">
                      <v-text-field
                        v-model="dados_proposta.parcela_inicial"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Parcela Inicial *"
                        label="Parcela Inicial *"
                        :rules="requiredRules"
                        type="number"
                        maxlength="100"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3" class="px-2">
                      <v-text-field
                        v-model="dados_proposta.parcela_final"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Parcela Final *"
                        label="Parcela Final *"
                        type="number"
                        :rules="requiredRules"
                        maxlength="100"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5">
                    <!-- DATA VENCIMENTO -->
                    <v-col cols="6" class="px-2">

                      <DatePicker
                        v-model="dados_proposta.empreendcobr_parc_dt"
                        label="Data Vencimento *"
                        class="mt-1"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense
                        placeholder="dia/mês/ano"
                        :rules="datavenctoRules"
                        >
                      </DatePicker>

                    </v-col>
                    
                    <!-- VALOR PARCELA -->
                    <v-col cols="6" class="px-2">
                      <VuetifyMoney
                        v-model="dados_proposta.empreendcobr_parc_valor"
                        class="mt-1"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor Parcela *"
                        label="Valor Parcela *"
                        reverse
                        required
                        :rules="valorRules"
                      >
                      </VuetifyMoney>
                    </v-col>
                  </v-row>   
                </fieldset>  

              </v-form>
            </v-col>
          </v-row>
        </div>  

      </v-card-text>
      
      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
          v-if="dados_editar.acao2 != 'VISUALIZAR'"
          @click="fechar()"
          class="mr-4 btn caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="dados_editar.acao2 == 'VISUALIZAR'"
          @click="fechar()"
          class="mr-4 btn caption font-weight-medium"
          color="primary"
          text
        >
          FECHAR
        </v-btn>
        <v-btn
        :disabled="!valid || !proposta_incluir"
        v-show="dados_editar.acao2 != 'VISUALIZAR'"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import store_ContasReceber from "./store_ContasReceber"
import { ContasReceberGet } from "../../../services/erp"
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import store_Financeiro from "../store_Financeiro";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO, MASK_CPFCNPJ, MASK_TELEFONE, } from "../../../services/constantes";
import {
  CpfCnpjIsValid,
  telefoneIsValid,
  formatDate,
  maskCpfCnpj
} from "../../../services/funcoes";
import moment, { now } from 'moment';
import store_site from "../../../store/store_site"
import ContasReceber from "./ContasReceber.vue";

export default {
  name: "ContasReceberNovoModal",

  props: ['dialog_novo_editar', 'dados_editar', 'dialogOpen'],

  components: {
    VuetifyMoney,
    DatePicker,
  },

  data() {
    return {
      store_Financeiro    : store_Financeiro,
      store_site          : store_site,
      store_ContasReceber : store_ContasReceber,
      ContasReceber       : ContasReceber,
      dialogOpen2          : this.dialogOpen,
 
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SUBTITULO     : COR_SUBTITULO,  
      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      formatDate        : formatDate,
      maskCpfCnpj       : maskCpfCnpj,

      checkbox1: true,
      checkbox2: false,

      proposta_incluir  : null,
      dados_proposta    : [],
      valid: true,
      form: false,

      /* SELECT AJUSTE */
      opcoes_tipo_parcela: [
                              { "id": "D", text: "D", value: "DESPESA" },
                              { "id": "P", text: "P", value: "PARCELA MANUAL" },
                           ],
      /* TEXTOS */
      datavenctoRules: [
        (value) =>
          (value && value.length >= 10) ||
          "Obs. Data de Vencimento deve ter no mínimo 8 caracteres",
        (value) =>
          (value && value.length <= 10) ||
          "Obs. Data de Vencimento deve ter menos do que 8 caracteres",
      ],

      observacaoRules: [
        (value) =>
          (value && value.length < 600) ||
          "Observação deve ter menos do que 600 caracteres",
      ],
      requiredRules:[
        (value) =>
          (!!value) ||
              "O campo é obrigatório",
      ],

      /* NÚMEROS */
      valorRules: [
        (value) => value != '0,00' || "O Valor é obrigatório",
        // (value) => /^\d+$/.test(value) || 'O Valor não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O Valor deve ter menos do que 10 caracteres",
      ],
      data_picker : {
        'min-width':'150px !important',
        //'max-width':'180px !important',
        //'margin-right':'8px !important',
        //'margin-top':'-15px !important',
        //'color':'red'
      }

    }
  },

  watch: {
    'dialogOpen2': {
      handler(newVal, oldVal) {
        console.log('AAAAAAA')
      },
      deep: true
    },
  },

  methods: {
    fechar() {
      this.proposta_incluir = null;
      this.dados_proposta = [];
      this.$emit('update:dialog_novo', false);
    },

    async buscaProposta(item){
      const ls_Res = await this.store_ContasReceber.ContasReceberBuscaProposta(item);
      this.dados_proposta = ls_Res.data.result[0] || {};
      console.log('busca Proposta',this.dados_proposta)
      if(!this.dados_proposta.cod_empreendvenda){
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Proposta não encontrada!'
        this.store_site.alert           = true;
        this.proposta_incluir = ''
      }
    },

    reset () {
      this.$refs.form.reset();
    },    
    
    async validate () {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {
        let dt_Cobranca = null;
        // Igualando datas tanto digitadas quando selecionadas
        if (this.dados_proposta.empreendcobr_parc_dt &&
            this.dados_proposta.empreendcobr_parc_dt != 'Invalid date' &&
            this.dados_proposta.empreendcobr_parc_dt != 'undefined' &&
            this.dados_proposta.empreendcobr_parc_dt.indexOf('T') >= 0)
          dt_Cobranca = this.dados_proposta.empreendcobr_parc_dt;
        else
          dt_Cobranca = moment(this.dados_proposta.empreendcobr_parc_dt, 'DD/MM/YYYY').format();
        console.log("dados_proposta", this.dados_proposta)
        const lo_JSON = { cod_empreendvenda                 : this.proposta_incluir,
                          cod_pessoa                        : this.dados_proposta.cod_pessoa,
                          empreendcobr_descricao            : "PARCELA",
                          empreendcobr_situacao             : "Aberto",
                          empreendcobrparc_parc_tipo        : "Mensal",
                          empreendcobr_aplica_amortizacao   : "SIM",
                          empreendcobr_tipo                 : "R",
                          empreendcobr_parcela_inicial      : this.dados_proposta.parcela_inicial,
                          empreendcobr_parcela_final        : this.dados_proposta.parcela_final,
                          empreendcobr_parc_dt              : dt_Cobranca,
                          empreendcobr_parc_valor           : this.dados_proposta.empreendcobr_parc_valor,
                          empreendcobr_parc_valor_final     : this.dados_proposta.empreendcobr_parc_valor,
                        } 
        if(!this.dados_proposta.cod_pessoa){
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = 'Proposta inválida'
          this.store_site.alert           = true;
          return
        }
        let ls_Res = null
        ls_Res = await this.store_ContasReceber.ContasReceberNovo(lo_JSON);
        if (ls_Res.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.data.result.result.trim();
          this.store_site.alert           = true;
          this.$emit('busca', lo_JSON);
          this.$emit('criarNovaConta', lo_JSON)
        } else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = ls_Res.data.errors.trim();
          this.store_site.alert           = true;
        }
      }
      this.proposta_incluir = null;
      this.dados_proposta = [];
      this.fechar()
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
  height: 450px;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  font-size: 19px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.title-page-menor {
  font-family: "Open Sans", sans-serif!important;
  font-size: 16px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 340px;
  overflow-y: auto;
}

.row-value {
  margin-top: -146px;
}

.btn {
  width: 100px;
}

.v-divider {
  background: var(--COR_PRINCIPAL)!important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

</style>

<style scoped lang="scss">

    .input-text {
        ::v-deep {
            .v-text-field {
                input {
                    font-size: 19px;
                }
            }
        }
    }
</style>