import Vue from "vue";
import { API } from "../../services/API"
import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";

var vm_store_Financeiro = new Vue({
    data () {
      return {
        dados              : [],

        dialogFiltroAvancado  : false,
        dialogUnidades        : false,

        docContasPagar                : 0,
        docContasReceber              : 0,
        docCnabRemessa                : 0,
        docCnabRetorno                : 0,
        docFechamentoMensal           : 0,

        // devedor_referencia_codigo_Modal  : null,
        // devedor_referencia_nome_Modal    : null,

        // devedor_pagador_codigo_Modal     : null,
        // devedor_pagador_nome_Modal       : null,

        abas: [
          ['mdi-cash-plus', 'Contas a Receber', 0],
          ['mdi-cash-minus', 'Contas a Pagar', 5],
          ['mdi-file-send-outline', 'CNAB Remessa', 0],
          ['mdi-file-restore-outline', 'CNAB Retorno', 0],
          ['mdi-calculator', 'Fechamento Mensal', 0],
        ],
      }
    },

    methods : {

      atualiza_abas() {
        this.abas = [
          ['mdi-cash-multiple', 'Contas a Receber', this.docFinanceiro],
          ['mdi-view-dashboard-outline', 'Contas a Pagar', 0],
          ['mdi-account-lock-open-outline', 'Privilégios', this.docPrivilegios],
          ['mdi-calculator', 'CNAB Remessa', this.docContabil],
          ['mdi-credit-card-outline', 'CNAB Retorno', this.docPlanosPagamento],
          ['mdi-file-find', 'Fechamento Mensal', this.docModelosImpressos],
        ]
      }
  
    //   async PessoaGet(p_params) {
    //     console.log("p_params", p_params)
    //     var lo_Params;
    //     if (p_codUsuario)
    //         lo_Params = {cod_usuario : p_codUsuario}
    //     else if (p_filtro)
    //         lo_Params = {filtro : p_filtro}
    //     else
    //         lo_Params = {}

    //     const resposta = await API.get("pessoa", {
    //                                                 params: {
    //                                                     ...p_params
    //                                                 },
    //                                                }
    //                                     );
    //     if (resposta) {
    //       this.dados = [];
    //       if (resposta.data.result != 'Não possui dados') {
    //         if (p_params && p_params.cod_pessoa) {
    //           console.log("this.usuario_selecionado--", resposta.data.result);
    //           this.usuario_selecionado = resposta.data.result;
    //           return resposta.data.result
    //         }
    //         else {
    //             this.dados   = resposta.data.result.rows;
    //             this.paginas = resposta.data.result.paginas;
    //         }
    //       }
    //     }
    //   },
  
    //   async PessoaPost(body) {
    //     const resp = await API.post(`/pessoa`, JSON.stringify(body));
    //     const { data } = resp;
    //     return data;
    //   },
        
    //   async PessoaPut(body) {
    //     const resp = await API.put(`/pessoa/${body.cod_pessoa}`, JSON.stringify(body))
    //     const { data } = resp;
    //     return data;
    //   },

    //   async PessoaDelete(body) {
    //     const resp = await API.delete(`/pessoa/${body.cod_pessoa}`)
    //     const { data } = resp;
    //     if (resp.status == 200) 
    //       return data.result;
    //     else
    //       return data;
    //   }
      
    },

    watch: {
      docContasReceber()           { this.atualiza_abas() },
      docContasPagar()             { this.atualiza_abas() },
      docCnabRemessa()             { this.atualiza_abas() },
      docCnabRetorno()             { this.atualiza_abas() },
      docFechamentoMensal()        { this.atualiza_abas() },
    },

})

export default vm_store_Financeiro
